import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { injectModel } from 'core-web/state';
import AlgoliaContainer from 'core-web/containers/AlgoliaContainer';

const toParamObject = (productIds, articleIds, hitsPerPage, attributesToRetrieve) => ({
    facets: ['*'],
    page: 1,
    hitsPerPage,
    filters: [
        productIds.map(id => `objectID:"${id}"`),
        articleIds.map(id => `external_id:"${id}"`),
        [...(articleIds.length ? ['post_type:post'] : '')],
    ].filter(i => i.length !== 0),
    ...(attributesToRetrieve?.length ? { attributesToRetrieve } : {}),
});

const ModuleContainer = ({
    application,
    articleAttributes,
    articleIds,
    articleIndex,
    pageSize,
    productAttributes,
    productIds,
    productIndex,
    render,
    renderProps,
    search,
}) => {
    const parameterListRef = useRef([]);
    const responseRef = useRef(null);

    const responseCallback = useCallback(response => {
        // @todo: This is only because responseCallback is called twice sometimes by AlgoliaContainer and gives wrong data.
        if (response) {
            const hitsData = {};

            parameterListRef.current.forEach((value, index) => {
                hitsData[value] = (response[index] || {}).hits || [];
            });

            responseRef.current = hitsData;
        }

        return responseRef.current;
    }, []);

    const getParameters = () => {
        const parameters = [];
        const parameterList = [];
        const locale = application.locale.toLowerCase();

        // todo: only get default once per changed filter default
        // default query
        if (productIds.length > 0) {
            parameterList.push('products');
            parameters.push({
                indexName: `${productIndex}_${locale}`,
                query: search,
                params: toParamObject(productIds, [], pageSize, productAttributes),
            });
        }

        if (articleIds.length > 0) {
            parameterList.push('articles');
            parameters.push({
                indexName: `${articleIndex}_${locale}`,
                query: search,
                params: toParamObject([], articleIds, pageSize, articleAttributes),
            });
        }

        parameterListRef.current = parameterList;

        return parameters;
    };

    return (
        <AlgoliaContainer
            parameters={getParameters()}
            responseCallback={responseCallback}
            render={props => render({ ...props, renderProps })}
        />
    );
};

ModuleContainer.propTypes = {
    application: PropTypes.shape({
        locale: PropTypes.string.isRequired,
    }).isRequired,
    articleAttributes: PropTypes.array,
    articleIds: PropTypes.array,
    articleIndex: PropTypes.string,
    pageSize: PropTypes.number,
    productAttributes: PropTypes.array,
    productIds: PropTypes.array,
    productIndex: PropTypes.string,
    render: PropTypes.func.isRequired,
    renderProps: PropTypes.object,
    search: PropTypes.string,
};

ModuleContainer.defaultProps = {
    articleAttributes: null,
    articleIds: [],
    articleIndex: 'posts',
    pageSize: 8,
    productAttributes: null,
    productIds: [],
    productIndex: 'products',
    renderProps: {},
    search: '',
};

export default injectModel('application')(ModuleContainer);
