import React, { Fragment } from 'react';
import styled from 'theme/libs/styled';
import Above from 'core-web/components/Breakpoints/Above';
import Below from 'core-web/components/Breakpoints/Below';
import Link from 'core-web/components/Link';
import colors from 'theme/config/colors';
import BigArrowIcon from 'theme/components/icons/BigArrowIcon';
import { above } from 'core-web/util/mediaqueries.js';
import PropTypes from 'prop-types';
import H3 from 'theme/components/base/headings/H3';
import H4 from 'theme/components/base/headings/H4';

const DefaultSpacing = styled('div')`
    ${above.md} {
        width: 100%;
        height: 8px;
    }

    ${above.lg} {
        height: 16px;
    }
`;

const Row = styled('div')`
    display: flex;
    align-items: center;
`;

const Wrapper = styled('div')`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 24px 8px;

    ${above.md} {
        padding: 32px 0;
        height: 86px;

        & > svg {
            margin-right: 8px;
        }
    }
`;

const StyledLink = styled(Link)`
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > div svg {
        transition: transform 0.25s ease;
    }
    ${above.lg} {
        &:hover > div svg {
            transform: translate(4px);
        }
    }
`;

const HeaderSeparator = ({ data }) => {
    const { text, link, is_active: isActive } = data;
    const { text: linkText, url } = link || {};

    return isActive !== undefined && !isActive ? (
        <DefaultSpacing />
    ) : (
        <Fragment>
            <Below
                breakpoint="lg"
                render={() => (
                    <Link to={url}>
                        <Wrapper>
                            {text && (
                                <H3 lineHeight="1.2em" fontSize="22px">
                                    {text}
                                </H3>
                            )}
                            {url && (
                                <BigArrowIcon width="31px" height="24px" color={colors.darkGrey} direction="right" />
                            )}
                        </Wrapper>
                    </Link>
                )}
            />
            <Above
                breakpoint="lg"
                render={() => (
                    <Wrapper height="auto" position="relative">
                        {/*//empty div for the IE*/}
                        <div>
                            {url && (
                                <StyledLink to={url}>
                                    {text && <H3 fontSize="22px">{text}</H3>}
                                    <div>
                                        <Row mr="12px">
                                            <H4 mr="16px" lineHeight="23px">
                                                {linkText}
                                            </H4>
                                            <BigArrowIcon
                                                width="31px"
                                                height="24px"
                                                color={colors.darkGrey}
                                                direction="right"
                                            />
                                        </Row>
                                    </div>
                                </StyledLink>
                            )}
                        </div>
                    </Wrapper>
                )}
            />
        </Fragment>
    );
};

HeaderSeparator.propTypes = {
    data: PropTypes.object,
};

HeaderSeparator.defaultProps = {
    data: {},
};

export default HeaderSeparator;
