import React from 'react';
import PropTypes from 'prop-types';
import ModuleContainer from 'core-web/containers/ModuleContainer';
import LoadingContainer from 'core-web/containers/LoadingContainer';
import HeaderSeparator from 'theme/components/HeaderSeparator';
import FeaturedProductsComponent from 'theme/components/FeaturedProducts';

const Loading = props => <LoadingContainer {...props} success={rest => <FeaturedProductsComponent {...rest} />} />;

const FeaturedProducts = ({ content }) => {
    const { campaign_poster: poster, products, header_data: header, data } = content;

    const productIds = (products || []).map(product => product.data.product);

    return (
        <>
            {header && <HeaderSeparator data={header} />}
            <ModuleContainer
                productIds={productIds}
                productAttributes={[
                    'name',
                    'image',
                    'subHeader',
                    'url',
                    'flags',
                    'type',
                    'pricing',
                    'objectID',
                    'onHand',
                    'manufacturer',
                    'parentProductId',
                ]}
                renderProps={{ poster, data }}
                render={Loading}
            />
        </>
    );
};

FeaturedProducts.propTypes = {
    content: PropTypes.object.isRequired,
};

export default FeaturedProducts;
